import React from 'react'
import { Link } from 'gatsby'
import { IconContext } from 'react-icons'
import { SiGithub, SiLinkedin, SiInstagram, SiMedium, SiYoutube, SiXing } from "react-icons/si"

const Footer = () => (
  <footer className="text-gray-50">
    <div className="react-icons-container flex flex-wrap justify-center gap-16">
      <IconContext.Provider value={{ className: 'react-icons' }}>
        <a href="https://github.com/Threefields" target="_blank" rel="noreferrer">
          <SiGithub />
        </a>
        <a href="https://medium.com/@threefields" target="_blank" rel="noreferrer">
          <SiMedium />
        </a>
        <a href="https://www.linkedin.com/in/stefanostrialonis" target="_blank" rel="noreferrer">
          <SiLinkedin />
        </a>
        <a href="https://www.xing.com/profile/Stefanos_Trialonis" target="_blank" rel="noreferrer">
          <SiXing />
        </a>
        <a href="https://www.youtube.com/channel/UCzrY0-NZbhirXlzWhWEUVxw" target="_blank" rel="noreferrer">
          <SiYoutube />
        </a>
        <a href="https://www.instagram.com/threefields/" target="_blank" rel="noreferrer">
          <SiInstagram />
        </a>
      </IconContext.Provider>
    </div>
    <div className="text-center">
      <Link to="/imprint">Imprint</Link>
      <br />
      <small>Copyright © {new Date().getFullYear()} Threefields Media</small>
    </div>
  </footer>
)

export default Footer