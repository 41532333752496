import React from 'react'
import { AnalyticsWrapper } from './analytics'
import Header from './header'
import Footer from './footer'

const Layout = ({ location, children }) => (
  <div className="flex flex-col min-h-screen justify-between leading-normal tracking-normal">
    <Header />
    <main className="container text-gray-50 mx-auto pt-10 pl-2 pr-2">
      {children}
    </main>
    <Footer />
    <AnalyticsWrapper />
  </div>
)

export default Layout