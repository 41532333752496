import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

const Header = () => (
  <header className="container mx-auto pl-2 pr-2">
    <nav id="header" className="container flex items-center justify-between mx-auto text-gray-50 font-bold mt-4 pl-4 pr-4 shadow-xl">
      <Link to="/" className="flex items-center">
        <StaticImage className="inline-block h-auto w-16" src="../images/threefields-logo.png" alt="Logo" />
        <span className="invisible md:visible">Threefields</span>
      </Link>
      <div className="space-x-6">
        <Link to="/" activeClassName="active">Home</Link>
        <Link to="/blog" activeClassName="active">Blog</Link>
        <Link to="/about" activeClassName="active">About</Link>
      </div>
    </nav>
  </header>
)

export default Header